.video-like-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.like-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  color: var(--light-blue-sky);
  font-size: 1.8rem;
  transition: all 0.2s ease;
}

.like-button:hover {
  transform: scale(1.1);
}

.heart-icon {
  color: var(--light-blue-sky);
  font-size: 1.8rem;
  transition: all 0.2s ease;
}

.heart-icon.liked {
  color: var(--red);
  text-shadow: 1px 1px 1px var(--carnation-pink);
}

.like-count {
  margin-left: 5px;
  font-size: 1rem;
  color: var(--light-blue-sky);
}

/* Modal styles matching MyPieceOfRandomRainbow.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-size: 0.6em;
}

.modal-content {
  background: var(--veronica-purple);
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  position: relative;
}

.modal-content h2 {
  color: var(--light-blue-sky);
  margin-top: 0;
  margin-bottom: 1rem;
}

.modal-content p {
  color: white;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  white-space: pre-line;
}

.modal-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.modal-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.modal-btn.confirm {
  background-color: #ff4081;
  color: white;
}

.modal-btn.confirm:hover {
  background-color: #f50057;
}

.modal-btn.cancel {
  background-color: #424242;
  color: white;
}

.modal-btn.cancel:hover {
  background-color: #616161;
}

.like-button.loading {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.like-button:disabled {
  cursor: not-allowed;
}

.liked-heart.loading {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
} 