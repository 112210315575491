.manifesto {
    padding: 15px;
    border-radius: 10px;
    color: var(--carnation-pink);
    -webkit-text-stroke: 1.2px var(--red);
    margin: 0 auto;
    font-family: "lores-12", sans-serif;
    font-weight: 400;
    font-size: 2em;
  }

  .how-it-works-container{
    max-width: 90%;
    margin: 0 auto;
  }

  .how-it-works{
    padding: 20px;
    border-radius: 10px;
    color: var(--red);
    -webkit-text-stroke: 1.2px var(--carnation-pink);
    margin: 0 auto;
    font-family: "lores-12", sans-serif;
    font-weight: 400;
    font-size: 1em;
  }

  .manifesto-container{
    max-width: 95%;
    margin: 0 auto;
  }

  .manifesto-title {
    color: var(--red);
    margin: 10px auto;
    font-family: "lores-12", sans-serif;
    font-weight: 700;
    font-size: 2em;
    -webkit-text-stroke: 1px var(--carnation-pink);
  }

  .manifesto h1 {
    line-height: 60px;
  }

  @media (max-width: 768px) {
    .manifesto h1 {
        font-size: 0.8em;
        line-height: 40px;
      }
    }

  .style1 {
    font-family: "lores-12", sans-serif;
    font-weight: 700;
  }

  .style2 {
    font-family: "lores-12", sans-serif;
    font-weight: 700;
    font-size: 1.1em;
  }

  .style3 {
    font-family: "lores-9-plus-narrow", sans-serif;
    font-weight: 400;
  }

  .style4 {
    font-family: "lores-9-plus-wide", sans-serif;
    font-weight: 400;
  }

  .manifesto-content {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }

  @media (max-width: 480px) {
    .manifesto {
        font-size: 1.2em;
        padding: 8px;
    }

    .manifesto h1 {
        font-size: 0.7em;
        line-height: 30px;
    }

    .manifesto-title {
        font-size: 1.2em;
    }

    .how-it-works {
        font-size: 0.8em;
        padding: 8px;
        -webkit-text-stroke: 0.2px var(--carnation-pink);
    }

    .manifesto-content {
        padding: 0.5rem;
    }
  }

