.admin-container {
  padding: 2rem;
  min-height: 100vh;
  font-family: "lores-9-plus-wide";
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.admin-title {
  font-size: 2.5rem;
  color: var(--veronica-purple);
  text-shadow: 2px 2px var(--maize-yellow);
  text-align: center;
  margin-bottom: 2rem;
}

.admin-loading {
  text-align: center;
  font-size: 1.5rem;
  color: var(--veronica-purple);
}

.admin-cards-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}

.admin-card {
  background-color: var(--maize-yellow);
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: 0 4px 15px var(--veronica-purple);
  transition: transform 0.2s ease;
  border: 3px solid var(--veronica-purple);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-card:hover {
  transform: translateY(-5px);
}

.admin-card-title {
  font-size: 1.2rem;
  color: var(--veronica-purple);
  margin-bottom: 1rem;
}

.admin-card-username {
  color: var(--veronica-purple);
  font-size: 1rem;
  margin-bottom: 1rem;
}

.admin-status-select {
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  border: 2px solid var(--veronica-purple);
  margin-bottom: 1rem;
  font-family: inherit;
  background-color: var(--maize-yellow);
  color: var(--veronica-purple);
}

.admin-error-message {
  color: var(--red);
  font-size: 0.9rem;
  margin: 0.5rem 0;
}

.admin-video-link {
  color: var(--veronica-purple);
  text-decoration: none;
  display: block;
  margin: 1rem 0;
}

.admin-video-link:hover {
  color: var(--light-blue-sky);
  text-shadow: 1px 1px var(--veronica-purple);
}

.admin-card-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.admin-btn {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-family: inherit;
  transition: all 0.2s ease;
}

.admin-btn-update {
  background-color: var(--light-blue-sky);
  color: var(--veronica-purple);
  border: 2px solid var(--veronica-purple);
}

.admin-btn-delete {
  background-color: var(--red);
  color: var(--maize-yellow);
  border: 2px solid var(--veronica-purple);
}

.admin-btn:hover {
  opacity: 0.9;
  transform: translateY(-2px);
  box-shadow: 2px 2px var(--veronica-purple);
}

.admin-toggle-btn {
  background-color: var(--veronica-purple);
  color: var(--maize-yellow);
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  border: 2px solid var(--maize-yellow);
  cursor: pointer;
  font-family: inherit;
  margin: 1rem 0;
  transition: all 0.2s ease;
}

.admin-toggle-btn:hover {
  background-color: var(--light-blue-sky);
  color: var(--veronica-purple);
  transform: translateY(-2px);
  box-shadow: 2px 2px var(--veronica-purple);
}


@media (max-width: 768px) {
  .admin-cards-grid {
    grid-template-columns: 1fr;
  }
  
  .admin-card:nth-last-child(-n+2),
  .admin-card:last-child {
    grid-column: auto;
  }
  
  .admin-card {
    margin: 1rem 0;
  }
}
