.video-item {
  background: linear-gradient(45deg, var(--carnation-pink), var(--veronica-purple));
  border: 2px solid var(--maize-yellow);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

}

.video-item:hover {
  background: linear-gradient(45deg, var(--veronica-purple), var(--light-blue-sky));
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-color: var(--carnation-pink);
}


.video-title h3 {
  color: #ffffff;
  margin-top: 0 auto;
  font-size: 1.2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  width: 100%;
}

.video-status-container {
  margin-top: 8px;
  color: var(--maize-yellow);
  font-size: 0.9rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-container {
  background: linear-gradient(45deg, var(--carnation-pink), var(--veronica-purple));
  border: 2px solid var(--maize-yellow);
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  width: 80%;
  max-width: 800px;
  transition: all 0.3s ease-in-out;
}

.profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.profile-info {
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.profile-info h2 {
  margin: 0;
  font-size: 1.8rem;
  color: var(--maize-yellow);
}

.profile-info p {
  margin: 5px 0;
  font-size: 1.1rem;
}



.video-list {
  width: 80%;
  max-width: 800px;
  margin: 20px 0;
}

.loading-container {
  color: var(--maize-yellow);
  font-size: 1.2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin: 20px 0;
}

.error-message {
  color: var(--red);
  background: rgba(228, 37, 37, 0.1);
  border: 2px solid var(--red);
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  text-align: center;
  width: 80%;
  max-width: 800px;
}


/* For any social media links or additional info */
.social-links {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.social-links a {
  color: var(--maize-yellow);
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: var(--light-blue-sky);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-container,
  .video-list {
    width: 95%;
  }

  .profile-header {
    flex-direction: column;
    text-align: center;
  }

  .default-btn {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .title-username{
    font-size: 2em !important;
    text-shadow: 
    2px 2px 0 var(--maize-yellow),
    4px 4px 0 var(--carnation-pink) !important; 
  }

  .title-username::before {
    content: '*';
    display: block; /* Put asterisk on its own line */
    margin-bottom: 10px;
  }
  
  .title-username::after {
    content: '*';
    display: block; /* Put asterisk on its own line */
    margin-top: 10px;
  }

  .description-user {
    -webkit-text-stroke: 0.5px var(--carnation-pink) !important; 
  }

  .social-media-container {
    margin: 0 !important;
  }

  .video-item{
    padding: 10px 20px 0px 20px;
  }
}

.title-username {
  position: relative;
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  margin: 0;
  color: var(--veronica-purple);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 
    2px 2px 0 var(--maize-yellow),
    4px 4px 0 var(--carnation-pink);
  transition: all 0.3s ease-in-out;
  font-family: lores-9-plus-narrow;
  
}

.title-username:hover {
  transform: translateY(-5px);
  text-shadow: 
3px 3px 0 var(--carnation-pink),
6px 6px 0 var(--light-blue-sky);
}

.description-user {
  position: relative;
  color: var(--red);
  max-width: 800px;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  font-size: 1em;
  line-height: 1.6;
  background-color: transparent;
  color: var(--red);
  -webkit-text-stroke: 0.8px var(--carnation-pink);
  font-family: "lores-12", sans-serif;
  font-size: 0.8em;
}




/* Social Media Link */
.social-media-container {
  margin: 0;
}

.social-media-container a {
  color: var(--veronica-purple);
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  position: relative;
  padding: 5px 0;

}

.social-media-container a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, var(--carnation-pink), var(--veronica-purple));
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.social-media-container a:hover {
  color: var(--carnation-pink);
}

.social-media-container a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


.error {
  background: linear-gradient(45deg, var(--red), #ff6b6b);
  color: white;
  padding: 15px 25px;
  border-radius: 8px;
  border: 2px solid var(--red);
  margin: 20px 0;
  text-align: center;
  max-width: 800px;
  width: 80%;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
.title-username::before {
  content: '*'; /* Put asterisk on its own line */
  margin-bottom: 10px;
}

.title-username::after {
  content: '*';
  margin-top: 10px;
}