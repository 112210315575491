.error-status {
  color: var(--red);
}

.special-btn {
  margin: 20px auto;
  display: block;
  background-color: transparent;
  color: var(--red);
  text-shadow: 2px 2px 1px var(--carnation-pink);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 0.9em;
  cursor: pointer;
  transition: all 0.2s ease;
}

.special-btn:hover {
  background-color: var(--veronica-purple);
  color: var(--light-blue-sky);
  text-shadow: 1px 1px 1px var(--carnation-pink);
  transform: scale(1.05);
}

.special-btn:disabled {
  background-color: var(--carnation-pink);
  color: var(--red);
  cursor: not-allowed;
  transform: none;
}

/* User menu styles */
.user-menu {
  margin-right: 2em;
  display: flex;
  flex-direction: row;
  gap: 2em;
  justify-content: right;
  align-items: center;
}

/* Add these styles */
.video-link {
  color: var(--carnation-pink);
  transition: all 0.2s ease;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: underline dotted;
  text-shadow: 1px 1px 1px var(--light-blue-sky);
}

.video-link:hover {
  color: var(--red);
  text-shadow: 1px 1px 1px var(--light-blue-sky);
}

/* Update button styles */
.icon-btn {
  padding: 8px 12px;
  border-radius: 5px;
  transition: all 0.2s ease;
  border: none;
  cursor: pointer;
}

.icon-btn:hover {
  transform: scale(1.1);
}

.video-timeline {
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 20px;
}

.timeline-item {
  display: flex;
  margin-bottom: 2rem;
  position: relative;
}

.timeline-marker {
  flex: 0 0 60px;
  position: relative;
}

.timeline-marker::after {
  content: '';
  position: absolute;
  top: 24px;
  left: 50%;
  bottom: -40px;
  width: 2px;
  background-color: #3a3a3a;
}

.timeline-item:last-child .timeline-marker::after {
  display: none;
}

.status-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3a3a3a;
}

.video-list {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: lores-9-plus-narrow;
}

.video-item {
  gap: 2rem;
  align-items: center;
  display: flex;
  flex-direction: center;
  align-items: center;
}


.video-link {
  background: var(--light-blue-sky);
  color: var(--veronica-purple);
  padding: 0.4rem 0.8rem;
  margin-bottom: 10px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.2s ease;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  
}

.video-link:hover {
  background: var(--maize-yellow);
  transform: translateY(-2px);
}

.video-status {
  border-radius: 4px;
  color: var(--light-blue-sky);
  transition: all 0.2s ease;
  font-size: 1.4em;
  text-shadow: 1px 1px 1px var(--red);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 9px;
}




.icon-btn {
  background: none;
  border: none;
  color: var(--light-blue-sky);
  cursor: pointer;
  padding: 0.4rem;
  transition: all 0.2s ease;
}

.icon-btn:hover {
  transform: scale(1.2);
}

.icon-btn:hover:first-child {
  color: var(--maize-yellow);
}

.icon-btn:hover:last-child {
  color: var(--red);
}

/* Responsive styles */
@media (max-width: 600px) {
  .video-list {
    padding: 0 10px;
  }

  .video-item {
    flex-direction: column;
    gap: 1rem;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-size: 0.6em;
}

.modal-content {
  background: var(--veronica-purple);
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  position: relative;
}

.modal-content h2 {
  color: var(--light-blue-sky);
  margin-top: 0;
  margin-bottom: 1rem;
}

.modal-content p {
  color: white;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  white-space: pre-line;
}

.modal-close-btn {
  background: var(--light-blue-sky);
  color: var(--veronica-purple);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.modal-close-btn:hover {
  background: var(--maize-yellow);
}

.title-link{
  text-decoration: none;
}

.video-title h3{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
}


.title-link:hover h3 {
  color: var(--maize-yellow);
}

.error-status:hover,
.guidelines-status:hover {
    color: var(--carnation-pink) !important;
    cursor: pointer;
} 

.modal-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.modal-btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease;
}

.modal-btn.confirm {
  background: var(--red);
  color: white;
}

.modal-btn.confirm:hover {
  background: #d32f2f;
  transform: translateY(-2px);
}

.modal-btn.cancel {
  background: var(--light-blue-sky);
  color: var(--veronica-purple);
}

.modal-btn.cancel:hover {
  background: var(--maize-yellow);
  transform: translateY(-2px);
}

.button-container{
  display: flex;
  flex-direction: row;
  font-size: 0.9em;
  gap: 100px;
}

.cannot-add-more{
  color: var(--carnation-pink);
  text-shadow: 1px 1px 1px var(--red);
}

.video-status-group{
  display: flex;
  gap: 50px;
  margin-left: 200px;
}

/* Update responsive styles */
@media (max-width: 600px) {
  .button-container {
    flex-direction: column;
    gap: 0px;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 0px;
  }

  .default-btn {
    width: 100%;
    padding: 12px;
    font-size: 1em;
  }

  .add-video-btn{
    font-size: 1.5em !important;
  }

  .video-list {
    padding: 0px;
    width: 100%;
    margin: 0px;
  }

  .video-link{
    margin-top:15px;
    margin-right: 20px;
  }

  .video-item {
    flex-direction: column;
    gap: 0;
    padding: 15px;
    width: 100%;
  }

  .video-title h3 {
    font-size: 1.1em;
    text-align: center;
    margin: 5px 0;
  }

  .action-buttons {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }

  .icon-btn {
    padding: 10px;
    font-size: 1.2em;
  }

  .mobile-profile-btn-group{
    display: flex;
  }
  .mobile-profile-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .video-status-group{
    gap:0px;
    margin: 0;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
}