.chat-container {
  width: 90%;
  max-width: 800px;
  margin: 2rem auto;
  height: 70vh;
  display: flex;
  flex-direction: column;
  font-family: "lores-9-plus-wide";
  padding: 2rem;
  border-radius: 8px;
  box-sizing: border-box;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto; /* Enables vertical scrolling within the chat */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  border-radius: 4px;
}

.message {
  position: relative;
  padding: 0.5rem;
  border-radius: 4px;
  max-width: 80%;
  color: var(--red);
  margin: 0.2rem auto;
  width: 100%;
}

.message:last-child::after {
  display: none;
}


.message-username {
  font-size: 0.7em;
  color: var(--carnation-pink);
  display: block;
  text-align: center;
}

.own-message .message-username {
  color: var(--red);
}

.message-content {
  font-size: 0.9em;
  line-height: 1.2;
  text-shadow: 1px 1px 1px var(--carnation-pink);
  text-align: center;
  padding: 0.3rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
}


.chat-input-form {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  background: transparent;
  border-top: 1px dotted var(--carnation-pink);
  margin-top: 1rem;
}

.chat-input {
  flex-grow: 1;
  resize: vertical;
  min-height: 40px;
  max-height: 120px;
  padding: 0.5rem;
  border: 1px solid var(--veronica-purple);
  border-radius: 4px;
  background: transparent;
  color: var(--red);
  font-family: inherit;
  font-size: 0.9em;
}

.send-btn {
  align-self: flex-end;
  background: var(--veronica-purple);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.8em;
}

.send-btn:hover {
  background: var(--red);
  transform: translateY(-2px);
}

.send-btn:disabled {
  background-color: #ccc; /* Light gray background */
  color: #666; /* Darker gray text */
  cursor: not-allowed; /* Change cursor to indicate non-clickable */
  opacity: 0.6; /* Slightly transparent */
}

.loading-sentinel {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-indicator {
  color: var(--carnation-pink);
  font-size: 0.8em;
  padding: 0.5rem;
}

.character-counter {
  font-size: 0.8em;
  color: var(--carnation-pink);
  text-align: right;
  margin-top: 0.5rem;
}

.message-username a {
  color: var(--carnation-pink);
  text-decoration: none;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.message-username a:hover {
  color: var(--veronica-purple);
  text-shadow: 1px 1px 2px var(--light-blue-sky);
}

.own-message .message-username a {
  color: var(--veronica-purple);
}

.own-message .message-username a:hover {
  color: var(--red);
  text-shadow: 1px 1px 1px var(--maize-yellow);
}

.own-message .message-content {
  color: var(--veronica-purple);
}

.more-options {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.more-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.5em;
  color: var(--carnation-pink);
}

.options-dropdown {
  position: absolute;
  right: 0;
  background: white;
  border: 1px solid var(--veronica-purple);
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;

}

.options-dropdown button {
  background: none;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  color: var(--red);
}

.options-dropdown button:hover {
  background: rgba(248, 231, 119, 0.1);
} 

.chat-header {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.5em;
  font-family: "lores-9-plus-wide";
}

.chat-header h1 {
  font-size: 1.5em;
  color: var(--veronica-purple);
  text-shadow: 1px 1px 1px var(--carnation-pink);
  margin: 10px auto;
}

.chat-header p {
  font-size: 0.5em;
  color: var(--red);
}

.chat-input-form-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  min-width: 80%;
}

.chat-input-form button{
  width: 100%;
  margin: 10px auto;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .chat-container {
    padding: 1rem;
  }

  .chat-header h1 {
    font-size: 0.8em;
    margin: 1em;
  }

  .chat-header p {
    font-size: 0.5em;
    margin: 2em;
  }

  .message-content {
    font-size: 0.8em;
  }

  .send-btn {
    font-size: 0.7em;
    padding: 0.4rem 0.8rem;
  }

  .chat-input-form{
    flex-direction: column;
  }
}