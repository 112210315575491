.user-avatar {
  margin-left: auto;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
  color: var(--red);
  font-size: 2em;
  margin: 30px 30px 0 30px;
  font-family: "lores-9-plus-wide";

}
.user-avatar:hover {
  cursor: url("../../images/rainbow-reverse.png"), auto;
  color: var(--veronica-purple);
  font-family: "lores-9-plus-wide";
}

.user-avatar h3{
font-size: 0.5em;
}

.icons-style {
  font-size: 2em;
  color: var(--red);
  text-decoration: none;
}

.all-icons{
  display: flex;
  justify-content: space-between;
  gap:30px;
}

.icons-style:hover{
  color: var(--lif);
}


.icons-group-on {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 80px !important;
  margin-right:0px;
  
}

.icons-group-off {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
  /* margin-right:90px; */
}

.menu-btn-desktop{
  font-size: 1.8em;
}


.icons-style:hover {
  color: var(--carnation-pink);
  cursor: url("../../images/rainbow-reverse.png"), auto;
}

.selected {
  color: var(--carnation-pink); 
  text-shadow: 2px 2px 1px #E42525;
}

.menu-btn{
  font-size: 3em;
  margin: 30px;
  margin-bottom: 0;
}

.menu-btn h3{
  font-size: 0.5em;
}


.menu-btn:hover{
  color: var(--carnation-pink);
}

.header-user-on{
  font-size: 1.8em;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
.mobile-menu{
  font-family: "lores-9-plus-wide";
}

  .user-avatar {
    display: none; /* Hide user avatar on the main page */
  }

  .mobile-menu.show .user-avatar {
    display: flex; /* Show user avatar when the menu is open */
    justify-content: center;
    margin: 10px;
    font-size: 1.5em; /* Adjust font size for mobile */
  }

  .user-avatar h3 {
    font-size: 0.8em; /* Adjusted for readability */
    font-family: "lores-9-plus-wide";
  }

  .icons-group-on {
    flex-direction: column; /* Stack items vertically */
    gap: 0px !important; /* Reduced gap for mobile */
    margin-right: 0;
  }

  .menu-btn {
    font-size: 2em; /* Smaller button size */
    margin: 10px;
  }

  .desktop-menu {
    display: none; /* Hide desktop menu on mobile */
  }

  .mobile-menu {
    display: none; /* Initially hidden */
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center items vertically */
    gap: 20px;
    height: 100vh; /* Full viewport height */
    padding: 0px 20px; /* Add padding for margin */
    box-sizing: border-box; /* Include padding in height calculation */
  }
  .mobile-menu.show {
    display: flex; /* Show menu when active */
  }

  .mobile-menu.hide {
    display: none; /* Hide menu when inactive */
  }

  /* Mobile Menu Button Styles */
  .menu-button {
    color: var(--red ); /* Use app's yellow color for text */
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto; /* Center the button horizontally */
    display: block; /* Ensure the button is a block element for centering */
    text-align: center; /* Center text within the button */
    font-family: "lores-9-plus-wide";
    text-shadow: 2px 2px 1px #FF99C9;
  }

  /* Mobile button styles */
  .mobile-menu .default-btn {
    background-color: var(--maize-yellow); /* Default button color */
    color: var(--red); /* Button text color */
    padding: 10px 20px; /* Padding for button */
    text-shadow: 1px 1px 1px var(--carnation-pink);
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    text-align: center; /* Center text */
    text-decoration: none; /* Remove underline */
    font-size: 1em; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    /* transition: background-color 0.3s ease; Smooth transition */
    margin: 15px 0; /* Add vertical spacing between buttons */
  }

  .mobile-menu .selected{
    background-color: #FF99C9;
    text-shadow: 1px 1px 1px var(--maize-yellow);
  }

  .mobile-menu .default-btn.selected:hover{
    background-color: #FF99C9;
    color: var(--red);
    text-shadow: 1px 1px 1px var(--maize-yellow);
  }

  .mobile-menu .default-btn:hover {
    background-color: var(--maize-yellow); /* Default button color */
    color: var(--red); /* Button text color */
    text-shadow: 1px 1px 1px var(--carnation-pink);
  }
  
}