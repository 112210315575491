.comments-container {
  width: 90%;
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: transparent;
  font-family: "lores-9-plus-wide";
}

.section-title {
  font-size: 1.8rem;
  color: var(--veronica-purple);
  text-align: center;
  margin-bottom: 1.5rem;
}

.comment-input-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 4px;
}

.comment-input {
  resize: vertical;
  min-height: 60px;
  border: 1px solid var(--veronica-purple);
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  background: transparent;
  color: var(--red);
}

.send-btn {
  align-self: flex-end;
  background: var(--veronica-purple);
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
  font-size: 0.5em;
}

.send-btn:hover {
  background: var(--red);
}

.login-message {
  text-align: center;
  font-size: 1rem;
  color: var(--red);
  margin-bottom: 1.5rem;
}

.comments-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.comment {
  border-bottom: 1px dotted var(--carnation-pink);
  position: relative;
}

.comment:last-child {
  border-bottom: none;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.6em;
  color: var(--carnation-pink);
}

.comment-meta {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.comment-username {
  font-size: 0.8rem;
  color: var(--red);
}

.comment-date {
  font-size: 0.6rem;
  color: var(--carnation-pink);
}

.comment-content {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--red);
  text-shadow: 2px 2px 1px var(--carnation-pink);
  text-align: left;
}

/* Edit mode styles */
.edit-comment-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.edit-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.save-btn,
.cancel-btn {
  background: none;
  border: none;
  color: var(--veronica-purple);
  cursor: pointer;
  font-size: 0.8em;
  padding: 0.3rem 0.6rem;
}

.save-btn:hover {
  color: var(--light-blue-sky);
}

.cancel-btn:hover {
  color: var(--red);
}

/* More options dropdown */
.more-options {
  position: relative;
  display: inline-block;
}

.more-btn {
  background: none;
  border: none;
  color: var(--veronica-purple);
  font-size: 1em;
  cursor: pointer;
}

.options-dropdown {
  position: absolute;
  right: 0;
  background: white;
  border: 1px solid var(--veronica-purple);
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.options-dropdown button {
  background: none;
  border: none;
  padding: 0.5rem 1rem;
  text-align: left;
  cursor: pointer;
  font-size: 0.8em;
  color: var(--veronica-purple);
}

.options-dropdown button:hover {
  background-color: var(--maize-yellow);
}

.no-comments-message {
  text-align: center;
  color: var(--veronica-purple);
  text-shadow: 1px 1px 1px var(--carnation-pink);
  font-size: 1.2rem;
  padding: 2rem;
  font-style: italic;
}

.login-prompt {
  text-align: center;
  padding: 2rem;
  border: 1px dashed var(--veronica-purple);
  border-radius: 4px;
  margin: 2rem 0;
}

.login-prompt p {
  color: var(--veronica-purple);
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.login-btn {
  background: var(--veronica-purple);
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.login-btn:hover {
  background: var(--red);
  transform: translateY(-2px);
} 