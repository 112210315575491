.title {
    display: inline-block;
    margin: 0 auto;
    font-size: 2.5em;
    color: var(--red);
    font-family: lores-9-plus-narrow;
    /* border-bottom: 10px dotted var(--red); */
    text-shadow: 2px 2px 1px var(--red);
    color: var(--carnation-pink);
    /* -webkit-text-stroke: 1px var(--light-green); */

  }

  @import url('https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&family=Protest+Strike&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sixtyfour+Convergence&display=swap');

  @import url("https://p.typekit.net/p.css?s=1&k=wia6mva&ht=tk&f=28316.28317.28336.28337.30361&a=85017181&app=typekit&e=css");
  
  @font-face {
    font-family:"forma-djr-micro";
    src:url("https://use.typekit.net/af/9f9862/00000000000000003b9ae4e0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9f9862/00000000000000003b9ae4e0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9f9862/00000000000000003b9ae4e0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
    }

  .player{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90vw;
    height: 50.625vw;
    max-width: 1200px;
    margin: 20px auto;
    background-color: transparent;
  }
  
  .artist {
    color: var(--red);
    margin: 10px auto;
    font-family: "lores-12";
    font-size:1.5em;
    -webkit-text-stroke: 1px var(--carnation-pink);
  }
  
  .artist:hover {
    color: #8447ff;
    -webkit-text-stroke: 1px var(--light-blue-sky);
  }
  
  .description {
    margin: 2em auto;
    color: var(--red);
    -webkit-text-stroke: 1.2px var(--carnation-pink);
    max-width: 90%;
    font-family: forma-djr-micro;
    line-height: 1.5;
    white-space: pre-line;
    text-align: left;
    padding: 1em;
    text-align: center;
    border-radius: 20px;
    font-size: 1.3em;
  }
  
  .duration {
    
    padding: 1em;
    font-size: 0.8em;
    margin: 20px auto 10px auto;
    max-width: 40%;
    min-width: 330px;
    border-radius: 10px;
    color: var(--carnation-pink);
    font-family: lores-9-plus-narrow;
    font-size: 1.2em;
  
  }
  .duration p{
    -webkit-text-stroke: 1px var(--red);
  }
  
  .duration-label {
    margin: 5px 0;
  }
  
  .duration-select {
    background-color: var(--yellow-maize);
    border-radius: 10px;
    padding: 0.2em;
    color: var(--red);
    border: 2px solid var(--carnation-pink);
    max-width: 80%;
    margin-left: 10px;
  }
  
  .btn-random-video {
    background-color: transparent;
    width: 90%;
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    border: none;
    border-radius: 4px;
    font-size: 2em;
    margin: 40px auto;
  }

  .btn-random-video-after {
    font-size: 1em;
  }

  .btn-random-video img {
    width: 100%;
    height: auto;
    max-width: 600px;
  }

  .scroll-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--carnation-pink);
    color: var(--red);
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: all 0.3s ease;
  }

  .scroll-top-button:hover {
    background-color: var(--red);
    color: var(--carnation-pink);
    transform: translateY(-3px);
  }

  .video-like-container {
    display: flex;
    justify-content: center;
    margin: 15px 0;
  }

  .like-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 15px;
    transition: all 0.2s ease;
    color: var(--light-blue-sky);
    font-size: 1.2em;
  }

  .like-button:hover {
    transform: scale(1.1);
  }

  .like-button.liked {
    color: var(--red);
  }

  .like-count {
    font-size: 0.9em;
    color: var(--light-blue-sky);
  }