/* 
COLOR PALLETE WEBSITE:
Carnation Pink: #FF99C9
Light Green: #BCF0BD
Maize Yellow: #F8E777
Veronica Purple: #8447FF
Light Blue Sky: #85C7F2
Red: #E42525
*/

@import url("https://p.typekit.net/p.css?s=1&k=wia6mva&ht=tk&f=28337&a=85017181&app=typekit&e=css");

@font-face {
  font-family: "lores-9-plus-wide";
  src: url("https://use.typekit.net/af/96a625/00000000000000007735bf8c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),
       url("https://use.typekit.net/af/96a625/00000000000000007735bf8c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),
       url("https://use.typekit.net/af/96a625/00000000000000007735bf8c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;}

/* buttons */

.default-btn {
  font-size: 1em;
  color: #E42525;
  text-shadow: 2px 2px 1px #FF99C9;
  background-color: transparent;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 10px auto;
  border: none;
  font-family: "lores-9-plus-wide";
}

.default-btn:hover {
  color: #BCF0BD;
  background: #FF99C9;
  text-shadow: 2px 2px 1px  #E42525;
  cursor: url("../images/rainbow-reverse.png"), auto;
}

.update-btn {
  background: #7ee280;
  color: #e42525;
  font-size: 1em;
}

.delete-btn {
  background: #e42525;
  color: #7ee280;
  font-size: 1em;
}

.special-btn {
  background: #8447ff;
  color: #f8e777;

}

.special-btn:hover {
  color: #e42525;
  background-color: #f8e777;
}

/* title */

.special-title {
  margin: 30px auto;
  max-width: 70%;
  min-width: 50%;
  text-shadow: 2px 2px #e42525;
  line-height: 60px;
  padding: 10px;
  color:#8447ff;
  font-size: 1.5em;
}

.loading-style{
  font-size: 0.5em;
}

.special-select {
  background-color: #85c7f2;
  border-radius: 10px;
  text-align: center;
  border: none;
  color: #8447ff;
  padding: 5px;
}

.title-username {
  color: #8447ff;
  text-shadow: 2px 2px 2px #e42525;
}

.description-user {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8447ff;
  background-color: #f8e777;
  padding: 5px;
  max-width: 80%;
  margin: 20px auto;
  font-size: 0.8em;
  border-radius: 5px;
}

.player {
  margin: auto;
  min-width: 50%;
  min-height: 50%;
  max-width: 80%;
  max-height: 80%;
}

.error-status:hover{
  color: #e42525;
}

@media (max-width: 768px) {
  .menu-btn {
    margin: 0;
  }

}
