.login-container {
  min-height: 100vh;
  font-size: 1.5em;
  color: #7ee280;
  font-family: "lores-9-plus-wide";
}

.login-form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--red);
  text-shadow: 2px 2px 1px var(--carnation-pink);
  font-size: 1.2em;
  font-family: "lores-9-plus-wide";
}

.login-input {
  display: flex;
  background-color: #DEE381;
  margin: 0.5em;
  max-width: 80%;
  color: var(--red);
  border: 3px solid var(--carnation-pink);
  border-radius: 10px;
  font-family: "lores-9-plus-wide";
}

.extra-btns{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
}

.log-in-btn{
    border: 2px solid var(--carnation-pink);
}

@media (max-width: 600px){
  .log-in-btn{
    max-width: 50%;
  }
}
