/* 
COLOR PALLETE WEBSITE:
Carnation Pink: #FF99C9
Light Green: #DEE381
Maize Yellow: #F8E777
Veronica Purple: #8447FF
Light Blue Sky: #85C7F2
Red: #E42525

*/

:root {
  --carnation-pink: #FF99C9;
  --maize-yellow: #DEE381;
  --veronica-purple: #A070FF;
  --light-blue-sky: #85C7F2;
  --red: #E42525;
}

@import url("https://p.typekit.net/p.css?s=1&k=wia6mva&ht=tk&f=28337&a=85017181&app=typekit&e=css");

@font-face {
  font-family: "lores-9-plus-wide";
  src: url("https://use.typekit.net/af/96a625/00000000000000007735bf8c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),
       url("https://use.typekit.net/af/96a625/00000000000000007735bf8c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),
       url("https://use.typekit.net/af/96a625/00000000000000007735bf8c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;}

@import url("https://use.typekit.net/wia6mva.css");

.App {
  text-align: center;
  cursor: url("../images/cursor.png"), auto;
  animation: cursorChange 1s infinite;
  margin: 0;
  padding: 1em 2em;
  overflow: auto;
  background: url("../images/background.png") no-repeat center center fixed;
  background-size: 110% 110%;  /* Changed from 110% 100% to cover */
  background-color: #FF99C9;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.manifesto-app{
  padding: 1em 2em;
  overflow: auto;
  background: url("../images/background.png") no-repeat center center fixed;
  background-size: 110% 110%;  /* Changed from 110% 100% to cover */
  background-color: #FF99C9;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}


html{
  background-color: #FF99C9;
}

.logo-title{
  max-width: 80%;
  margin-left: 30px; 
  margin-top: -10px;
}


.random-button{
  max-width: 20%;
}

.cards-style {
  background-color: blue;
  color: #e42525;
  padding: 1em;
  border-radius: 10px;
}

.App-header {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: #7ee280;
  font-family: "lores-9-plus-wide";
}

.App-header video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.input-form {
  display: flex;
  background-color: #DEE381;
  margin: 0.5em;
  max-width: 80%;
  color: var(--red);
  border: 3px solid var(--carnation-pink);
  border-radius: 10px;
  font-family: "lores-9-plus-wide";
}

.sign-in-form{
  font-size: 0.8em !important;
}

.group-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:var(--red);
  text-shadow: 2px 2px 1px var(--carnation-pink);
  font-size: 0.8em;
  font-family: "lores-9-plus-wide";
  margin-top: 20px;
}


.app-title {
  font-family: Jersey;
  font-size: 5em;
  color: #7ee280;
  text-shadow: 2px 2px #8447ff;
}

.changing-colors {
  margin: 0.5em auto;
  animation: colorChange 30s infinite alternate;
}


.user-menu {
  margin-right: 2em;
  display: flex;
  flex-direction: row;
  gap: 2em;
  justify-content: right;
  align-items: center;
}

/* email verification */
.email-confirmation {
  color: red;
  margin: 2em;
}

.email-confirmation-child {
  margin: 1em;
  line-height: 2em;
}

.email-confirmation h2 {
  text-shadow: 2px 2px 5px #7ee280;
}

.none-text-decoration {
  text-decoration: none;
  color: #8447ff;
}

.none-text-decoration:hover {
  color: #7ee280;
  text-shadow: 1px 1px #8447ff;
}

/* Add new video */

.add-video-style{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-video-form {
  margin: 2em auto;
}

/* error */

.error {
  color: #e42525;
}


/* fonts */

@font-face {
  font-family: Jersey;
  src: url(../fonts/Jersey10Charted-Regular.ttf);
}

@font-face {
  font-family: bitmap;
  src: url(../fonts/8-bit-pusab.ttf);
}

/* Mobile Menu Styles */
.mobile-menu {
  display: none; /* Hide the mobile menu by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 255, 255, ); */
  /* background-color: var(--maize-yellow); */
  padding: 1em 2em;
  overflow: auto;
  /* background: url("../images/background-manifesto.png") no-repeat center center fixed; */
  background-size: 110% 110%;  /* Changed from 110% 100% to cover */
  background-color: var(--light-blue-sky);
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  z-index: 1000;
  text-align: center;
}

.hidden-title {
  display: none;
}

.mobile-menu.active {
  display: flex;
}

.logout {
  margin-top: auto;
  font-size: 1.5em;
  margin-bottom: 1em;
}

.menu-button {
  display: none;
}

.desktop-menu{
  display: flex;
  justify-content: center;
  gap:10px;
}

/* Mobile Styles */
@media (max-width: 768px) {

  .logo-title{
    margin-top: 20px;
    margin-left: 0px;
  }

  .menu-logo {
    margin-top: -70px !important;  /* Added negative margin to move logo up */
    min-width: 140%;
    padding: 0px 20px;
  }

  .menu-user-on{
    margin-top: -150px !important;  /* Added negative margin to move logo up */
    min-width: 140%;
    padding: 0px 20px;
  }

   .desktop-menu{
    display: none;

  }

  .hidden-title {
    display: flex;
  }

  .icons-group {
    flex-direction: column;
  }

  .icon-menu {
    margin-right: 10px;
    color: #f8e777;
  }

  .menu-button {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f8e777;
    text-shadow: 3px 3px #8447ff;
    border: none;
    padding: 1em;
    font-size: 1.5em;
    cursor: pointer;
  }

  .duration-select {
    font-size: 0.7em;
  }

  .sign-in-form{
    font-size: 1em !important;
  }

}

.create-account-btn{
  border: 2px solid var(--carnation-pink) !important;
  padding: 40px;
}

.desktop-menu{
  display: flex;
  justify-content: center;
  width: 100%;  /* Make sure it takes full width */
}



/* Container styling */
.header-container {
  width: 100%;
  text-align: center;
}

.desktop-menu {
  display: flex;
  justify-content: center;
  width: 100%;
}



/* Mobile styles */
@media (max-width: 768px) {


  .mobile-menu .icons-group {
    flex-direction: column;
    position: static;
    gap: 10px;
  }

  .mobile-menu .icons-group .default-btn {
    position: static;
    margin: 0;
  }
}
/* 

.default-btn {
  background: linear-gradient(45deg, var(--veronica-purple), var(--light-blue-sky));
  color: white;
  border: 2px solid var(--maize-yellow);
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.default-btn:hover {
  background: linear-gradient(45deg, var(--carnation-pink), var(--veronica-purple));
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-color: var(--light-blue-sky);
} */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 90%;
  color: black;
  font-size: 1.4em;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.modal-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-btn.confirm {
  background-color: #007bff;
  color: white;
}

.modal-btn:hover {
  opacity: 0.8;
}